/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

:root {
    --menu-bg: #0e1f33;
    --shadow-color: rgba(8, 21, 66, 0.04);
    --border: #f0f2fd;
    --white: #fff;
    --color-leftmenu: #868f9a;
    --text-color: #4E525E;
    --font-main: "Outfit", sans-serif;
    --color-blue: #007AFF;
    --color-green: #60b057;
}

/* ------Scrollbar CSS Start----- */
/* Firefox */

/* width */
::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--color-blue);
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--color-blue);
}


/* ------Scrollbar CSS End----- */

* {
    font-family: var(--font-main);
    transition: 0.5s;
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--font-main) !important;
    margin-top: 0 !important;
    font-weight: 500 !important;
    line-height: 1.2 !important;
    color: var(--bs-heading-color);
}

a {
    text-decoration: none;
}

ul {
    margin: 0;
    padding: 0;
}

li {
    list-style: none;
    margin: 0;
    padding: 0;
}
.error-element{
    color: red;
}
.custom-tooltip {
    position: absolute;
    background: black;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    white-space: nowrap;
    transform: translateY(-100%);
    transition: opacity 0.3s ease-in-out;
  }
  
.app-sidebar {
    position: fixed;
    inset-block: 0;
    inset-inline-start: 0;
    width: 150px;
    background: var(--menu-bg);
    overflow: auto;
    box-shadow: 0 1.563rem 3.125rem var(--shadow-color);
    transition: all 100ms ease;
    z-index: 999;
    font-family: var(--font-main);
}

.app-sidebar__header {
    display: flex;
    align-items: center;
    height: 4.125rem;
    padding: 0.625rem 1.25rem;
    width: 150px;
    background: var(--menu-bg);
    position: fixed;
    z-index: 999;
    inset-block-start: 0;
    inset-inline: 0;
    transition: all 100ms ease;
}

.main-logo {
    text-align: center;
    margin: 0 auto !important;
}

.main-logo img {
    margin: 0 auto;
    max-width: 100%;
}

.main-sidemenu {
    margin-block-start: 66px;
    padding: 15px 18px;
}

.main-sidemenu ul {
    margin: 0;
    padding: 0;
}

.main-sidemenu ul li {
    margin: 0;
    padding: 0 0 12px;
}

.main-sidemenu ul li span {
    text-align: center;
    display: block;
    padding: 15px 13px;
    cursor: pointer;
    background: transparent;
    border-radius: 8px;
}

.main-sidemenu ul li span:hover {
    background: #162e4a52;
}

.main-sidemenu ul li span img {
    margin: 0 auto 10px auto;
    transition: 0.0s;
}

.main-sidemenu ul li span h4 {
    color: var(--color-leftmenu);
    font-size: 12px;
    letter-spacing: 0.6px;
    font-weight: 400;
    margin-bottom: 0;
}

.main-sidemenu ul li span:hover img {
    filter: brightness(0) saturate(100%) invert(39%) sepia(82%) saturate(4206%) hue-rotate(198deg) brightness(99%) contrast(111%);
}

.main-sidemenu ul li span:hover h4 {
    color: var(--color-blue);
}

.app-content {
    min-height: 100vh;
    background: #f5f6fa;
    padding-inline-start: 150px;

}

.app-header {
    padding: 15px;
    background: var(--white);
    box-shadow: var(--shadow-color);
    height: 66px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #f5f6fa;
}

.app-header h3 {
    margin-bottom: 0;
    font-size: 16px;
    color: var(--text-color);
    font-weight: 500;
    line-height: normal;
}

.info-fluid {
    padding: 15px;
}

.info_box {
    border-radius: 4px !important;
    border: solid 1px #efefef !important;
    height: 100%;
}

.info_box .card-header {
    padding: 8px 15px;
    background: var(--white);
    border-color: #efefef !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;
}

.info_box .card-header .title {
    font-weight: 500;
    font-size: 14px;
    color: var(--text-color);
}

.user-box {
    display: flex;
    align-items: center;
}

.user-img {
    width: 36px;
    height: 36px;
    flex-shrink: 0;
    border-radius: 50%;
    overflow: hidden;
}

.user-img img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    background: #f0f7ef;
}

.user-dtls {
    padding-left: 13px;
}

.user-dtls h4 {
    font-size: 13px !important;
    font-weight: 500;
    margin-bottom: 4px;
    color: var(--text-color);
    line-height: normal;
}

.user-dtls h5 {
    font-size: 10px;
    color: #a7aab4;
    margin-bottom: 0;
    font-weight: 400;
}

.btndiv a {
    font-size: 12px;
    color: var(--color-blue) !important;
}

.btndiv a i {
    position: relative;
    top: 1px;
}

.info_box h1,
.info_box h2,
.info_box h3 {
    font-size: 13px;
    color: var(--text-color);
}

.info_box p {
    font-size: 12px;
    color: var(--text-color);
    margin-bottom: 10px;
}
.info_box p:last-child {
    margin-bottom: 0;
}
.info_box ul {
    margin: 0;
    padding: 0;
}

.info_box ul li {
    font-size: 12px;
    margin-bottom: 5px;
    list-style: none;
    line-height: 1.2;
}

.piegraph-describe li span {
    margin-top: 5px;
}

.card-body {
    padding: 15px;
}

.halfday_date {
    background-color: #ff3410 !important;
}

.present_date {
    background-color: #60b057 !important;
}

.absent_date {
    background-color: #fc6860 !important;
}

.holiday_date {
    background-color: #ffac10 !important;
}

.piegraph-img {
    text-align: center;
}

.piegraph-img img {
    max-width: 100%;
    width: 155px;
}

.piegraph-describe {
    display: flex;
    align-items: center;
    justify-content: center;
}

.piegraph-describe li {
    list-style: none;
    margin: 0;
    padding: 10px 15px;
    text-align: center;
    color: #8a8e9c;
    font-size: 10px !important;
    position: relative;
    margin-bottom: 0 !important;
}

/* .piegraph-describe li:first-child {
    padding-left: 0;
}

.piegraph-describe li:last-child {
    padding-right: 0;
} */

.piegraph-describe li span {
    display: block;
    color: var(--menu-bg);
    font-size: 14px;
    font-weight: 500;
}

.advance-div {
    height: calc(53.5% - 20px);
    margin-bottom: 15px;
}

.advance-div:last-child {
    margin-bottom: 0px;
}

.advance-div ul li {
    flex: 1;
}

.piegraph-describe li::after {
    content: "";
    width: 1px;
    height: 32px;
    border-right: solid 1px #e7eaf1;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.piegraph-describe li:last-child::after {
    border-right: 0;
}

.leave-details {
    display: flex;
}

.leave-details h5 {
    font-size: 12px;
    margin-bottom: 5px;
}

.leave-details h5 span {
    color: var(--color-blue);
}

.leave-details h6 {
    font-size: 12px;
    font-weight: 400;
    color: var(--color-green);
    margin-bottom: 0;
}

.leave-iocn {
    width: 8px;
    height: 8px;
    background-color: #ffac10;
    margin-top: 3px;
    margin-right: 6px;
    border-radius: 50%;
}

.iconblue {
    background-color: var(--color-blue);
}

.icongreen {
    background-color: var(--color-green);
}

.iconred {
    background-color: #fc6860;
}

.iconpurpal {
    background-color: #9a5cff;
}

.icongreenyello {
    background-color: #d0d312;
}

.reqleave {
    margin-top: 66px;
}

.reqleave a {
    display: block;
    color: var(--color-blue) !important;
    cursor: pointer;
    font-size: 12px;
    margin-bottom: 5px;
}

.text-gray {
    color: var(--color-leftmenu) !important;
}







.cm_tx {
    font-size: 12px;
    display: flex;
    align-items: center;
    margin-right: 10px;
    color: #ffac10;
}

.cm_tx .leave-iocn {
    margin-top: 0;
}

.acvc {
    color: #a7aab4;
    font-size: 10px;
}

.textblue {
    color: var(--color-blue);
}

.textgreen {
    color: var(--color-green);
}

.textgreen .leave-iocn {
    background-color: var(--color-green);
}

.textred {
    color: #fc6860;
}

.textorg {
    color: #f04b10;
}

.disableFile {
    pointer-events: none;
}

.textred .leave-iocn {
    background-color: #fc6860;
}
.textorg .leave-iocn {
    background-color: #f04b10;
}

.table tr th {
    font-weight: 500;
    background-color: #fcfcfc;
    color: var(--text-color);
}

.table tr th,
.table tr td {
    padding: 12px 15px;
    font-size: 12px;
}

.table tr td {
    border-bottom: 0;
    color: var(--color-leftmenu);
    font-weight: 400;
}

.usdetails {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;
}



/* right details */
.right-details {
    background-color: var(--white);
    height: 100%;
    width: 100%;
    /* border-top: 2px solid #f5f6fa; */
}

.right-details h2 {
    font-size: 14px;
    padding: 14px 15px;
    position: relative;
    margin-bottom: 0;
    line-height: normal;
}

.right-details h2::after {
    content: "";
    position: absolute;
    left: 0;
    width: 3px;
    height: 18px;
    background-color: var(--color-blue);
    border-radius: 0 8px 8px 0;

}

.userdtlsright {
    padding: 20px 15px;
}

.border-bottom {
    border-bottom: solid 1px #E7EAF1;
}

.userr-img {
    width: 70px;
    height: 70px;
    margin: 0 auto;
    overflow: hidden;
    border: solid 5px var(--white);
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 15px;
}

.userr-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.userdtlsright h4 {
    font-size: 14px;
    color: var(--text-color);
    margin-bottom: 5px;
}

.userdtlsright h5 {
    font-size: 10px;
    color: #8A8E9C;
}

.userdtlsright h6 {
    font-size: 13px;
    margin-top: 13px;
    color: var(--text-color);
}

.userdtlsright h6 span {
    color: #6F7880;
}

.repotinghad {
    background: #FFF6E5;
    padding: 16px;
    position: relative;
    border: solid 1px #FFAC10;
    border-radius: 5px;
    margin-top: 30px;
}

.repotinghad label {
    position: absolute;
    top: -6px;
    font-size: 13px;
    background: #fff6e5;
    line-height: 6px;
    padding: 0 4px;
    color: #FFAC10;
}

.shifttimingmain {
    padding: 15px;
}

.timebox {
    background: transparent linear-gradient(90deg, #FFAC10 0%, #FFFFFF 50%, #FFAC10 100%) 0% 0% no-repeat padding-box;
    padding: 1px;
}

.timemain {
    background-color: var(--white);
    padding: 15px;
}

.timemain span {
    font-size: 13px;
    color: var(--color-green);
    display: block;
    text-align: center;
    margin-bottom: 5px;
}

.timemain strong {
    font-weight: 600;
    font-size: 14px;
    display: block;
    text-align: center;
    color: var(--text-color);
}

.timemain .col {
    position: relative;
}

.timemain .col::after {
    content: "";
    width: 1px;
    height: 40px;
    border-right: solid 1px #E7EAF1;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

.timemain .col:last-child::after {
    display: none;
}

.button-solide {
    background-color: var(--color-green) !important;
    color: var(--white) !important;
    font-size: 13px;
    height: 40px;
    line-height: 0;
    border-radius: 4px;
    box-shadow: unset !important;
    border: 0;
    flex-shrink: 0;
}

.button-solide:hover {
    background-color: #32742a !important;
    color: var(--white) !important;
}

.bg-blue {
    background-color: var(--color-blue) !important;
}

.bg-blue:hover {
    background: #0b61bf !important;
}

.add-button {
    background-color: transparent !important;
    color: var(--color-blue) !important;
    border-color: var(--color-blue);
    font-size: 13px;
    height: 38px;
    line-height: 0;
    border-radius: 4px;
    box-shadow: unset !important;
    min-width: 100px;
}

.add-button:hover,
.addbtn {
    background-color: var(--color-blue) !important;
    color: var(--white) !important;
}

.cs_select {
    width: 114px;
    position: relative;
    outline: none !important;
    box-shadow: none !important;
    border-radius: 4px;
    font-size: 12px;

}

.cs_select:focus {
    border-color: #a5a5a5;
}

.cs_select {
    height: 40px;
    border-color: var(--color-blue);
    color: var(--color-blue);
    font-weight: 600;
    background-color: #E6F2FF;
    background-image: url(assets/images/download.png);
    background-repeat: no-repeat;
    background-size: 11px;
}

.cs_select:focus {
    border-color: var(--color-blue);
}

.cs_dark {
    color: var(--text-color);
    border-color: #E7EAF1;
    background-image: url(assets/images/download-dark.png);
    background-color: transparent;
}


.cs_dark:focus {
    background-image: url(assets/images/download.png);
    border-color: var(--color-blue);
    color: var(--color-blue);
}

.right-details h3 {
    font-size: 13px;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    justify-content: center;
}

.right-details h3 i {
    color: #FFAC10;
    font-size: 22px;
    margin-right: 8px;
    position: relative;
    top: -1px;
}

.right-details h3 i.bi-cloud-moon-fill {
    color: var(--color-blue);
}

.timing-arive {
    display: flex;
    margin: 0;
    padding: 0;
}

.timing-arive li {
    list-style: none;
    flex: 1;
    position: relative;
    padding: 0 10px;
}

.timing-arive li::after {
    content: "";
    width: 1px;
    height: 90%;
    background-color: #d5d5d5;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.timing-arive li strong {
    display: block;
    font-size: 12px;
    font-weight: 600;
    color: var(--text-color);
    margin-bottom: 4px;
}

.timing-arive li span {
    display: block;
    font-size: 11px;
    color: var(--color-leftmenu);
    line-height: normal;
}

.timing-arive li:first-child {
    padding-left: 0;
}

.timing-arive li:last-child {
    padding-right: 0;
}

.timing-arive li:last-child::after {
    display: none;
}

.late_time {
    font-size: 13px;
    background: #FFEFEF;
    padding: 8px 15px;
    color: #FC6860;
    border-radius: 2px;
}

.early_arival {
    font-size: 13px;
    background: #E6F2FF;
    padding: 8px 15px;
    color: var(--color-blue);
    border-radius: 2px;
}

.customecalendar {
    border: 0;
    border-radius: 0 !important;
    background: var(--white) !important;
    box-shadow: none;
}

.mat-calendar-body-cell-content,
.mat-calendar-table-header th,
.mat-calendar-body-label {
    color: var(--menu-bg);
}

.mat-mdc-button:not(:disabled) {
    color: var(--menu-bg) !important;
}

.mat-calendar-arrow {
    fill: var(--menu-bg) !important;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgb(206 229 253) !important;
}

.mat-calendar-body-selected {
    background-color: var(--color-blue);
    color: #fff;

}

.mat-calendar-controls {
    padding: 0 0 10px 0;
    margin: 0 !important;
}

.mat-calendar-header {
    padding-top: 0 !important;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    border-color: var(--color-blue);
    background-color: rgb(206 229 253) !important;
}

.mat-mdc-button:not(:disabled) {
    border: solid 1px #E7EAF1;
    font-size: 13px !important;
    color: var(--text-color);
}

.p-15 {
    padding: 15px;
}

.average_time li {
    flex: unset;
    padding: 0 12px;
}

.height100 {
    height: calc(100vh - 66px);
    overflow-y: auto;
}

.height100::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

.timing_div {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E7EAF1;
    border-radius: 4px;
    padding: 22px 15px;
    margin-bottom: 15px;
    height: calc(50% - 8px);
}

.timing_div h3 {
    font-size: 14px;
}

.timing_div:last-child {
    margin-bottom: 0;
}

.timing_div ul {
    display: flex;
    padding: 0;
}

.timing_div ul li {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    padding: 5px 40px;
    position: relative;
    color: #8A8E9C;
    font-weight: 400;
    font-size: 13px;
}

.timing_div ul li::after {
    content: "";
    width: 1px;
    height: 80%;
    top: 0;
    right: 0;
    background-color: #dee2e6;
    position: absolute;
}

.timing_div ul li strong {
    display: block;
    color: var(--text-color);
    padding-bottom: 5px;
    font-weight: 600;
}

.timing_div.att ul li {
    text-align: center;

}

.timing_div ul li:first-child {
    padding-left: 0;
}

.timing_div ul li:last-child {
    padding-right: 0;
}


.timing_div ul li:last-child:after {
    display: none;
}

.apllylive a {
    color: #0e1f33;
    font-size: 13px;
}

.title-div {
    font-size: 14px !important;
}

.boxshodo {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E7EAF1;
    border-radius: 4px;
}

.boxshodo h3 {
    font-size: 14px;
    color: var(--text-color);
}

.lev-box ul {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.lev-box ul li strong {
    display: block;
    color: var(--text-color);
    padding-bottom: 5px;
    font-weight: 600;
}

.lev-box ul li {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    padding: 0px 40px;
    position: relative;
    color: #8A8E9C;
    font-weight: 400;
    font-size: 13px;
    text-align: center;

}

.lev-box ul li span {
    display: block;
}

.lev-box ul li span:last-child {
    font-weight: 600;
}

.lev-box ul li::after {
    content: "";
    width: 1px;
    height: 80%;
    top: 0;
    right: 0;
    background-color: #dee2e6;
    position: absolute;
}

.lev-box ul li:last-child:after {
    display: none;
}

.col-20 {
    flex: 0 0 auto;
    width: 20%;
}

.col-80 {
    flex: 0 0 auto;
    width: 80%;
}

.holedaylist {
    width: 20%;
}

.upcoming_holiday .usdetails {
    border: solid 1px #E7EAF1;
    padding: 5px 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    background-color: var(--white);
}

.upcoming_holiday .usdetails:hover {
    background-color: #F5F6FA;
}

.upcoming_holiday .usdetails:last-child {
    margin-bottom: 0;
}

.upcoming_holiday .usdetails .user-dtls h4 {
    margin-bottom: 1px;
}

.leave-requests .table tr td {
    border-bottom: 1px solid #E7EAF1;
}

.table tr td {
    vertical-align: middle;
}

.table tr td strong {
    display: block;
    font-weight: 500;
    color: var(--text-color);
    font-size: 12px;
    line-height: normal;
}

.table tr td span {
    display: block;
    color: #8A8E9C;
    font-size: 12px;
}



/* dropdown select style start */
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: #E6F2FF;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: var(--color-blue);
}

.mat-mdc-select-value {
    color: var(--color-blue);
}

.mdc-text-field--filled .mdc-line-ripple::after {
    border-bottom-color: var(--mdc-theme-primary, #007bff00);
}

/* dropdown select style end */

.error-element {
    color: red;
}

.gray-btn {
    background-color: rgb(238, 231, 231) !important;
    width: auto;
    padding: 0 20px;
    min-width: 100px;
    color: black !important;
}

.gray-btn:hover {
    background-color: rgb(189 189 189) !important;
    color: #333 !important;
}

.blue-btn {
    background-color: var(--color-blue) !important;
    width: auto;
    padding: 0 20px;
    min-width: 100px;
}

.blue-btn:hover {
    background-color: #185eab !important;
}

.calender-icon {
    color: var(--color-blue);
}

.filter-icon i {
    font-size: 22px;
    color: #8A8E9C;
}

/* login page design  */

.loginmain {
    width: 100%;
    min-height: 100vh;
}

.loginbg {
    background: #0E1F33;
}

.login-bg {
    width: 100%;
    max-width: 600px;
    padding: 30px;
}

.login-bg .login-logo {
    margin-bottom: 20px;
}

.login-bg .login-logo img {
    width: 250px;
}

.login-bg p {
    color: #fff;
    font-weight: 400;
}

.login-form {
    width: 100%;
    max-width: 350px;
}

.login-form .form-append{
    display: flex;
    align-items: center;
}


.mat-mdc-form-field-infix {
    display: flex;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: #0E1F33 !important;

}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {

    /* border-color: var(--color-blue) !important; */
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
    font-size: 14px;
    color: #0E1F33 !important;
}

.material-icons {
    font-size: 18px;
}

.blue-button {
    background-color: var(--color-blue) !important;
    width: auto;
    padding: 0 20px;
    min-width: 100px;
    color: var(--white) !important;
    font-size: 13px;
    height: 50px;
    line-height: 0;
    border-radius: 4px;
    box-shadow: unset !important;
    border: 0;
}

.login-form h3 {
    margin-bottom: 8px;
    text-align: center;
    font-size: 18px;
}

.login-form p {
    margin-bottom: 20px;
    text-align: center;
}

.fc .fc-toolbar-title {
    font-size: 14px;
    margin: 0px;
}

.fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 10px;
}

.month-selector {
    display: flex;
    align-items: center;
}

.month-box {
    width: 175px;
    flex-shrink: 0;
    overflow: scroll;
    margin: 0 10px;
    border: solid 1px #E7EAF1;
    border-radius: 4px;
    padding: 12px 12px;
    text-align: center;
}

.month-box h3 {
    font-size: 13px;
    margin-bottom: 10px;
    font-weight: 600 !important;
}

.month-box h4 {
    font-size: 12px;
    margin-bottom: 0;
    font-weight: 400 !important;
}

.topfinance-nav ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.topfinance-nav ul li {
    margin-right: 30px;

}

.topfinance-nav ul li a {
    font-size: 13px;
    color: #8A8E9C !important;
    font-weight: 500;
    cursor: pointer;
    display: inline-block;
}

.topfinance-nav ul li.active a {
    color: var(--color-blue) !important;
}

.salary-overview {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.salary-overview li {
    font-size: 14px;
    padding: 6px 15px 6px 0px;
    min-width: 160px;
}

/* .salary-overview li:first-child{
    padding-left: 0;
}
.salary-overview li:last-child{
    padding-right: 0;
} */
.salary-overview li h5 {
    font-size: 13px;
    margin-bottom: 6px;
    color: var(--text-color);
}

.salary-overview li p {
    font-size: 12px;
    margin-bottom: 0;
    color: #8A8E9C;
    line-height: 1.5;
}

.buttonbluelight {
    background-color: #E6F2FF;
    color: var(--color-blue);
    font-size: 12px;
    cursor: pointer;
    white-space: nowrap;
}

.buttonbluelight:hover {
    background-color: var(--color-blue);
    color: #fff;
}

.activecolor {
    color: var(--color-green);
    font-size: 13px;
    font-weight: 500 !important;
}

.sal-details {
    margin-bottom: 15px;
    border-radius: 5px;
    cursor: pointer;
}

.sal-details:last-child {
    margin-bottom: 0;
}

.regular-salary h4 {
    margin-bottom: 0;
    font-size: 13px;
    color: var(--text-color);
    font-weight: 600 !important;
}

.regular-salary p {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 12px;
    color: #8A8E9C;
}

.color-blue {
    font-size: 12px;
    color: var(--color-blue);
}

.sal-details {
    padding: 15px;
    border: solid 1px #E7EAF1;
}

.custom-wid {
    width: 120px !important;
}

.font-13 {
    font-size: 13px;
}

/* 13.03.2024 */

.monthbox {
    margin: 0 !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E7EAF1;
    border-radius: 4px;
    text-align: center;
    color: #6F7880 !important;
    font-size: 13px !important;
    line-height: 20px;
    padding: 16px 10px;
}

.monthbox strong {
    display: block;
    color: var(--text-color);
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
}

.monthsec .owl-nav .owl-prev,
.monthsec .owl-nav .owl-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: 0;
    background: var(--text-color);
    border-radius: 50px;
    width: 22px;
    height: 22px;
    line-height: 24px;
    font-size: 10px !important;
    padding: 0;
    padding: 0;
    background-color: #4E525E !important;
    padding: 0 !important;
    border-radius: 50% !important;
    margin: 0 !important;
}

.monthsec .owl-nav .owl-prev {
    left: -8px;
}

.monthsec .owl-nav .owl-next {
    right: -8px;
}

.info_box .card-header .title span {
    color: var(--text-color);
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    display: inline-block;
}

.info_box .card-header .title small {
    color: #8A8E9C;
    margin-left: 5px;
}

.shiftbox {
    border-bottom: 1px solid #EFEFEE !important;
    padding: 12px 15px;
}

.shifhead span {
    color: var(--text-color);
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
}

.shifhead span i {
    font-style: normal;
    color: #6F7880;
}

ul.workinghours {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 15px;
}

ul.workinghours li {
    flex: 0 0 48%;
    max-width: 48%;
    background: #E7EAF1;
    margin: 0;
    padding: 5px 5px;
}

ul.workinghours li:nth-child(2) {
    background: #E6F2FF;
}

ul.workinghours li p strong {
    display: block;
    color: var(--text-color);
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
}

ul.workinghours li p {
    margin: 0;
    color: #8A8E9C;
    font-size: 12px;
    line-height: 18px;
}

.shiftbox1 p {
    color: #6F7880;
    font-size: 14px;
    line-height: 20px;
}

.shiftbox1 ul {
    display: flex;
    flex-wrap: wrap;
}

.shiftbox1 ul li {
    background: #ccc;
    padding: 3px 6px;
    font-size: 11px;
    margin: 0;
    font-weight: 500;
    margin: 5px;
}


.shiftbox1 ul li.in {
    background: #F0F7EF;
    color: var(--color-green);
}

.shiftbox1 ul li.out {
    background: #FFEFEF;
    color: #FC6860;
}

.shiftbox1 ul li.bteak {
    background: #FFF6E5;
    color: #FFAC10;
}

.shiftbox1 ul li.early {
    background: #F6EFFF;
    color: #9C5BFF;
}

.shiftbox1 ul li span {
    margin-right: 5px;
}

.shiftbox.shiftbox1 {
    border-bottom: 0 !important;
}

.maindoor {
    margin-top: 10px;
}

.maindoor p {
    font-weight: 500;
    color: var(--text-color);
    font-size: 14px;
    line-height: 20px;
}

.shiftbox.maindoorsec {
    border-bottom: 0 !important;
}

.doorentry li {
    margin-bottom: 0 !important;
}

.doorentry li+li {
    margin-top: 10px;
}

.doorentry li span+span {
    margin-left: 20px;
}

.doorentry li span {
    font-weight: 500;
}

.doorentry li span i {
    margin-right: 5px;
}

.doorentry li span:nth-child(1) i {
    color: var(--color-green);
}

.doorentry li span:nth-child(2) i {
    color: #FC6860;
}

.profilemenu {
    border: 1px solid #EDEEF6;
    border-radius: 4px;
    background: #fff;
    min-height: calc(100vh - 30px);
    overflow-y: auto;
    padding: 10px 0;
    position: sticky;
    top: 0px;
}

.profilemenu li.active span,
.profilemenu li:hover span {
    background: transparent linear-gradient(90deg, var(--unnamed-color-e6f2ff) 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(90deg, #E6F2FF 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    border-left: 4px solid var(--color-blue);
    color: var(--color-blue);
}


.profilemenu li+li {
    margin-top: 1px;
}

.profilemenu li {
    margin-bottom: 4px;
}

.profilemenu li span {
    display: block;
    padding: 10px;
    border-left: 4px solid #fff;
    font-size: 13px;
    list-style: none;
    font-weight: 500;
    cursor: pointer;
}

.border_bottom {
    border-bottom: 1px solid #EDEEF6;
    flex: none;
}

.proimg {
    width: 50px;
    height: 50px;
    border: 2px solid #fff;
    border-radius: 50px;
    box-shadow: 0 0 10px #ccc;
}

.selected-background {
    border-color: var(--color-blue);
    background-color: #E6F2FF;
}

.outstanding-amount p {
    margin-bottom: 4px;
}

.outstanding-amount h4 {
    font-size: 18px;
    color: #FC6860;
    margin-bottom: 8px;
}

.recover-till-date h3 {
    color: var(--color-green);
    margin-bottom: 5px;
    font-size: 22px;
    font-weight: 600 !important;
}

.recover-till-date p {
    color: #8a8e9c;
    margin-bottom: 0;
}

.tab-custom {
    background-color: #fff;
}

.tab-custom .mat-mdc-tab-header {
    background-color: var(--white) !important;
    border-bottom: 1px solid #E7EAF1;
}

.tab-custom .mat-mdc-tab-labels {
    justify-content: start !important;
}

.tab-custom .mat-mdc-tab-labels .mdc-tab {
    flex-grow: unset !important;
    padding: 0 15px !important;
}

.tab-custom .mat-mdc-tab-labels .mdc-tab .mdc-tab__text-label {
    color: #8A8E9C !important;
    font-size: 13px !important;
    letter-spacing: 0;
    font-weight: 500;
}

.tab-custom .mat-mdc-tab-labels .mdc-tab.mdc-tab--active .mdc-tab__text-label {
    color: var(--color-blue) !important;
}

.tab-custom .mdc-tab-indicator .mdc-tab-indicator__content {
    border-color: transparent !important;
}

.border-bottom {
    border-bottom: 1px solid #E7EAF1;
}

.cl-amount strong {
    font-weight: 500 !important;
    font-size: 14px;
    color: var(--text-color);
    margin-bottom: 5px;
}

.color-blue {
    color: var(--color-blue) !important;
}

.color-green {
    color: var(--color-green) !important;
}

.color-orange {
    color: #FC6860 !important;
}

.rotet-div {
    position: relative;
    width: 15px;
    height: 15px;
    display: inline-block;
}

.rotet-div i {
    position: absolute;
    transform: rotate(45deg);
    left: 0;
    top: 0;
    font-size: 16px;
}

.pendingbtn {
    border: solid 1px #FFAC10;
    background-color: #FFF6E5;
    color: #FFAC10 !important;
    display: inline-block !important;
    padding: 1px 10px;
    border-radius: 2px;
}

.greenbutton {
    border: solid 1px var(--color-green);
    background-color: var(--color-green);
    color: #fff !important;
    display: inline-block !important;
    padding: 1px 10px;
    border-radius: 2px;
}

.approvedbutton {
    border: solid 1px var(--color-green);
    background-color: #F0F7EF;
    color: var(--color-green) !important;
    display: inline-block !important;
    padding: 1px 10px;
    border-radius: 2px;
}

.rejectedbutton {
    border: solid 1px #FC6860;
    background-color: #FFEFEF;
    color: #FC6860 !important;
    display: inline-block !important;
    padding: 1px 10px;
    border-radius: 2px;
}

.left-line {
    position: relative;
}

.left-line::after {
    content: "";
    left: -15px;
    position: absolute;
    width: 3px;
    height: 16px;
    background: var(--color-blue);
    transform: translateY(-50%);
    top: 50%;
    border-radius: 0 5px 5px 0;
}

.derktext {
    color: #1D232A;
}

.declaredmain {
    display: flex;
    align-items: center;
}

.w-140 {
    width: 140px !important;
}

.w-170 {
    width: 170px !important;
}

.declared_amount {
    margin-right: 50px;
}

.declared_amount span {
    color: #8A8E9C;
    font-size: 12px;
}

.declared_amount strong {
    color: var(--text-color);
    font-size: 13px;
    font-weight: 600;
}

.rented_house {
    display: flex;
    align-items: start;
}

.add-hous h5 {
    font-size: 12px;
}

.price-hous h5 {
    font-size: 12px;
    color: var(--text-color);
    margin-bottom: 2px;
}

.price-hous p {
    color: var(--color-leftmenu);
    font-size: 12px;
    margin-bottom: 2px;
}

.doc-box {
    width: 70px;
    height: 70px;
    background: #FFEFEF;
    text-align: center;
    line-height: 70px;
    flex-shrink: 0;
    border-radius: 4px;
    font-size: 16px;
}
.doc-box img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
}

.updoc-box {
    width: 69px;
    height: 69px;
    background: #fff;
    text-align: center;
    border: dashed 1px #333;
    flex-shrink: 0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.updoc-box i {
    color: #4E525E;
}

.doucment-div h5 {
    color: #8A8E9C;
    margin-bottom: 15px;
}

.doc-text {
    padding-left: 15px;
}

.doc-text h3 {
    font-size: 14px;
    margin-bottom: 8px;
}

.doc-text h5 {
    margin-bottom: 0;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
    background-color: #F5F6FA !important;
    border-radius: 0 !important;
}

.btn-close {
    background-color: transparent;
    border: 0;
    box-shadow: unset !important;
    flex-shrink: 0;
}

.btn-close i::before {
    vertical-align: 0;
    opacity: 0;
}

.w-100px {
    min-width: 100px;
}

.modal-header {
    background: #FCFCFC;
}

.modal-text {
    padding-right: 30px;
}

.modal-text p {
    color: #8A8E9C;
    font-size: 12px;
}

.lfsection {
    width: 200px;
    flex-shrink: 0;
}

.lfsection p {
    color: #8A8E9C;
    margin-bottom: 6px;
    font-size: 12px;
}

.lfsection h5 {
    color: var(--color-blue);
    margin-bottom: 0;
    font-size: 14px;
}

.risection {
    width: 100%;
}

.risection h4 {
    font-size: 13px;
    margin-bottom: 5px;
}

.risection label,
.upload-doc label {
    font-size: 12px;
    margin-bottom: 6px;
    color: #8A8E9C;
}

.risection h4 {
    font-size: 13px;
    margin-bottom: 5px;
    color: #4E525E;
}

.borderredius-0 {
    border-radius: 0;
}

.form-group {
    position: relative;
    margin-bottom: 28px !important;
}
.mb-0.form-group{
    margin-bottom: 0 !important;
}
.form-group label {
    position: absolute;
    top: -10px;
    left: 12px;
    z-index: 1;
    background: #fff;
    padding: 0 5px;
    font-size: 12px;
    margin-bottom: 6px;
    color: #8A8E9C;
    border-radius: 4px;
}
.form-group label span {
    position: absolute;
    right: -3px;
    top: -1px;
}
.error-element{
    font-size: 12px;
}
.form-control,
input,
textarea {
    font-size: 12px;
    height: 40px;
    border-radius: 4px;
    font-weight: 500;
    padding: 10px;
}

textarea {
    height: 100px !important;
}
.status-container {
    display: flex;
    align-items: center; /* Aligns button and icon in the center */
    gap: 8px; /* Adds spacing between button and icon */
  }
.cancel_button {
    background-color: #d9dce0; /* Gray color */
    color: rgb(0, 0, 0);
    padding: 1px 10px;
    border: 1px solid #f9fcff;
  }
.form-control:focus {
    border-color: var(--bs-blue);
    box-shadow: unset;
}

.hr_cs {
    border-color: #8f8f8f !important;
}

/* .myprofileright{
    height: calc(100vh - 96px);
    overflow-y: auto;
} */
.amount input {
    padding-right: 15px;
}

.amount span {
    position: absolute;
    right: 10px;
    top: 10px;
}

.uploadpopup .updoc-box {
    width: 90px;
    height: 90px;
    border-color: #8A8E9C;
}

.upload-show {
    width: 100px;
    height: 90px;
    border: solid 1px #ebebeb;
    border-radius: 4px;
    position: relative;
    margin-right: 20px;
}

.uploadoverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    display: none;
    align-items: center;
    justify-content: center;
    background: #4e525e99;
    border-radius: 4px;
    top: 0;
}

.uploadoverlay span {
    padding: 5px;
    font-size: 13px;
    cursor: pointer;
    margin: 0 2px;
    color: #fff;
}

.upload-show:hover .uploadoverlay {
    display: flex;
}

.upload-show img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    object-fit: contain;
}

.buttongray {
    background-color: #E7EAF1 !important;
    color: #4E525E !important;
}

.bg-light {
    background-color: var(--white) !important;
}

.height_h100 {
    min-height: 100vh;
}

.payslip_box {
    width: 100%;
    padding-bottom: 100%;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
}

.payslip_box::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000000a3;
}

.payslip_box img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.pdfic {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 180px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    z-index: 1;
    opacity: 0.5;
}

.pdfic i {
    font-size: 60px;
    color: var(--white);
}


.titlepay {
    position: absolute;
    width: 100%;
    text-align: center;
    padding: 20px 10px;
    z-index: 1;
    color: #fff;
    text-transform: uppercase;
    font-size: 13px;
}

.actionbtn {
    display: none;
}

.payslip_box:hover .actionbtn {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    flex-wrap: wrap;
}

button.ac-btn {
    width: 50px;
    border: 0;
    margin: 4px;
    border-radius: 4px;
    line-height: 30px;
    background: var(--color-blue);
    color: #fff;
    font-size: 20px;
}

/* sm style responsive */
.col-md-2.rightstyke {
    width: 330px;
    flex: auto;
    flex-shrink: 0;
}

.col-md-10.flex-nowrap {
    flex: auto;
}

.heightsaldetails .boxshodo,
.rightsalerydetails .boxshodo {
    height: calc(100vh - 230px) !important;
    overflow-y: auto;
}

.col-md-2.profile-left {
    width: 300px;
    flex: auto;
    flex-shrink: 0;
}

.col-md-10.profile-right {
    width: 100%;
    flex: auto;
    padding-left: 0 !important;
}

.col-md-10.leftpanel-at {
    flex: auto;
}

.col-md-2.rightpanel-at {
    width: 330px;
    flex: auto;
    flex-shrink: 0;
}

.col-md-3.heightsaldetails {
    width: 330px;
    flex: auto;
    flex-shrink: 0;
}

.col-md-9.rightsalerydetails {
    flex: auto;
}

/* 01-04-2024 */

.border-bottom {
    border-color: #efefef !important;
}

.ann-minheight {
    max-height: 220px;
    overflow-y: auto;
    margin-bottom: 15px;
}

.card-body.border-bottom:last-child {
    border: 0 !important;
}



.cmnt {
    font-size: 14px;
}


.ng-star-inserted td .absent_date {
    /* position: relative; */
    background-color: transparent !important;
}

.ng-star-inserted td .absent_date::after {
    content: "";
    width: 6px;
    height: 6px;
    position: absolute;
    border-radius: 50%;
    background-color: #FC6860;
    left: 50%;
    transform: translateX(-50%);
    top: unset;
    bottom: 6px;
}

.ng-star-inserted td .present_date {
    background-color: transparent !important;
}

.ng-star-inserted td .present_date::after {
    content: "";
    width: 6px;
    height: 6px;
    position: absolute;
    border-radius: 50%;
    background-color: var(--color-green);
    left: 50%;
    transform: translateX(-50%);
    top: unset;
    bottom: 6px;
}

.ng-star-inserted td .present_date {
    background-color: transparent !important;
}

.ng-star-inserted td .present_date::after {
    content: "";
    width: 6px;
    height: 6px;
    position: absolute;
    border-radius: 50%;
    background-color: var(--color-green);
    left: 50%;
    transform: translateX(-50%);
    top: unset;
    bottom: 6px;
}


.ng-star-inserted td .holiday_date {
    background-color: transparent !important;
}

.ng-star-inserted td .holiday_date::after {
    content: "";
    width: 7px;
    height: 7px;
    position: absolute;
    border-radius: 50%;
    background-color: #FFAC10;
    left: 50%;
    transform: translateX(-50%);
    top: unset;
    bottom: 3px;
}


.ng-star-inserted td .halfday_date {
    background-color: transparent !important;
}

.ng-star-inserted td .halfday_date::after {
    background-color: #ff3410 !important;
}

.mat-calendar-body-cell-content {
    border-radius: 4px !important;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    border-color: #007affb3;
    background-color: rgb(206 229 253 / 59%) !important;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgb(206 229 253 / 59%) !important;
}

.col-md-10.flex-nowrap {
    flex: auto;
}

.col-md-2.rightstyke {
    width: 330px;
    flex: auto;
    flex-shrink: 0;
}

.wid80 {
    width: 100px;
    padding-right: 28px;
    background-position: 80px;
}

.sm-style {
    font-size: 13px;
    line-height: 24px;
}

ngx-select-dropdown.form-control {
    padding: 0 !important;
}

ngx-select-dropdown.form-control .ngx-dropdown-button {
    border: 0 !important;
    height: 38px;
}

.ngx-dropdown-container .ngx-dropdown-button .nsdicon-angle-down:before {
    width: 8px !important;
    height: 8px !important;
    top: 2px !important;
}

.checksm {
    top: -2px;
    position: relative;
    margin-right: 8px;
    width: 16px;
    height: 16px;
}

legend.fom_title {
    margin-bottom: 20px;
    font-size: 13px;
    font-weight: 600;
}

.btn-add {
    color: var(--white) !important;
    background-color: var(--color-blue) !important;
    display: flex;
    align-items: center;
    font-size: 12px;
}

.btn-add:hover {
    color: var(--white) !important;
    background-color: #0555ad !important;
}

.btn-deletee {
    color: var(--white) !important;
    background-color: #dc3545 !important;
    display: flex;
    align-items: center;
    font-size: 12px;
}

.btn-deletee:hover {
    color: var(--white) !important;
    background-color: #a81221 !important;
}

.detailsmain h5 {
    margin-bottom: 0;
    font-size: 13px;
    font-weight: 600 !important;
}

.detailsmain h6 {
    color: #333;
    font-size: 13px;
    margin-bottom: 0;
}

.selected-item span {
    color: var(--white) !important;
}

/* .selected-item span.nsdicon-close{
    width: 24px;
    height: 24px;
    background-color: #fff;
    background-image: url(assets/images/close.png);
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: center;
    font-size: 0px !important;
} */

.selected-item span.nsdicon-close {
    width: 21px;
    height: 21px;
    background-color: #ffffffdb;
    background-image: url(assets/images/close.png);
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: 9px;
    background-position: center;
    font-size: 0px !important;
}

.selected-item {
    padding: 5px 5px !important;
    display: flex !important;
    align-items: center;
    column-gap: 10px;
}

.available-items .available-item {
    padding: 3px 0px !important;
}

.row-gap {
    row-gap: 10px;
}

.fc .fc-daygrid-day-top {
    padding: 6px 20px;
    justify-content: start;
}

.fc .fc-daygrid-day-number {
    color: #4E525E;
}

.fc .fc-col-header-cell-cushion {
    padding: 10px 10px;
    color: #6F7880;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 13px;
    width: 100%;
    background: #F5F6FA;
}

.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 22px;
    padding-top: 40px;
}

.fc .fc-daygrid-event-harness {
    /* width: 100%;*/
    height: 100%;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end {
    width: 100%;
    height: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    padding: 5px;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end:focus {
    box-shadow: unset !important;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end i {
    font-style: normal !important;
    font-size: 14px;
    font-weight: 600;
}

.fc .fc-highlight {
    background: rgb(242 248 255) !important;
    z-index: -1 !important;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end.absent {
    background-color: #FFEFEF;
    color: #FC6860;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end.present {
    background-color: #F0F7EF;
    color: #60B057;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end.paid_leave,
.fc-direction-ltr .fc-daygrid-event.fc-event-end.casual_leave,
.fc-direction-ltr .fc-daygrid-event.fc-event-end.other_leave {
    background-color: #F6EFFF;
    color: #9C5BFF;
}


.fc-theme-standard td,
.fc-theme-standard th {
    border: 1px solid #f5f6fa;
}

.fc-theme-standard .fc-scrollgrid {
    border: 1px solid #f5f6fa;
}


.fc .fc-daygrid-day.fc-day-today {
    background-color: transparent !important;
    position: relative;
}

.fc .fc-daygrid-day.fc-day-today::after {
    content: "";
    position: absolute;
    left: 0;
    width: 26px;
    height: 26px;
    background-color: #007aff;
    top: 0;
    right: 0;
    left: unset;
    border-radius: 0 0 0 30px;
    z-index: 9;
}

.col-md-9.rightsalerydetails {
    flex: auto;
}

.col-md-3.heightsaldetails {
    width: 330px;
    flex: auto;
    flex-shrink: 0;
}

.livminheight {
    min-height: 128px;
}

@media screen and (min-width: 1400px) and (max-width: 1700px) {
    .chart-container{
        width: auto !important;
    }
}

@media screen and (min-width: 1400px) {
    .card.info_box .col-xxl-4 {
        width: 45%;
    }
    .card.info_box .col-xxl-8{
        padding-left: 15px;
        width: 55%;
    }
}

@media only screen and (max-width: 1600px) {
    .info_box ul li {
        font-size: 12px;
    }
    .cl-amount strong{
        font-size: 13px;
    }
    .livminheight {
        min-height: 110px;
    }

    .lev-box ul li {
        padding: 0px 20px;
    }

    .col-20 {
        width: 25%;
    }

    .col-80 {
        width: 75%;
    }
}
@media only screen and (max-width: 1400px) {
    .reqleave.mt-2 {
        margin-top: 0 !important;
    }
    .leave-details.mb-3 {
        margin-bottom: 10px !important;
    }
    .chart-container{
        width: 150px !important;
    }
}
@media only screen and (max-width: 991px) {
    .user-dtls{
        padding-right: 10px !important;
    }
    .height100 {
        height: auto;
    }
    .cdk-overlay-pane{
        width: calc(100% - 50px) !important;
    }
    .col-md-3.heightsaldetails {
        width: 50% !important;
    }

    .col-md-2.rightstyke {
        width: 100% !important;
        padding: 15px !important;
    }

    .dashbordmain .row.flex-nowrap {
        flex-wrap: wrap !important;
    }

}

.chart-container {
    width: 200px;
    margin: 0 auto;
    margin-bottom: 5px;
}

@media all and (max-width: 1399px) {
    /* .piegraph-describe li {
        padding: 10px 10px;
    } */
    .overview-chart canvas{
        width: 100% !important;
        height: auto !important;
    }
    .livminheight {
        min-height: auto;
    }

    .info_box ul {
        margin: 10px 0 !important;
    }

    .ann-minheight {
        max-height: 320px;
    }

    .button-solide {
        min-width: auto !important;
        padding: 0 15px !important;
    }

}

@media all and (max-width: 1360px) {

    .chart-container {
        width: 155px;
    }

    .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
        padding: 10px;
        padding-top: 30px;
    }

    .fc .fc-daygrid-day-number {
        padding: 0;
    }

    .fc .fc-daygrid-day-top {
        padding: 6px 10px;
    }

    .flex-nowrap.attendancemain {
        flex-wrap: wrap !important;
    }

    .col-20 {
        width: 33.33%;
    }

    .col-80 {
        width: 66%;
    }
}

@media all and (max-width: 991px) {
    .fc .fc-daygrid-day-frame {
        height: 100px !important;
    }
    .app-content{
        overflow-y: auto;
    }
    .cdk-global-overlay-wrapper{
        align-items: self-start !important;
        padding-top: 100px;
    }

    .topfinance-nav ul li {
        margin-right: 15px;
        margin-bottom: 6px;
    }

    .col-md-2.profile-left {
        width: 200px;
        padding-left: 0 !important;
    }

    .col-md-10.profile-right {
        width: 50%;
    }

    .fc .fc-daygrid-day.fc-day-today::after {
        width: 16px;
        height: 16px;
    }

    .col-20 {
        width: 50%;
    }

    .lev-box ul li {
        padding: 0px 18px;
    }

    .col-20.lefthodilies {
        width: 33.33%;
    }

    .col-20.lefthodilies {
        width: 100%;
    }

    .col-80 {
        width: 100%;
    }
}



/* input[type="checkbox"]{
    height: auto;
} */


.ngx-dropdown-container .ngx-dropdown-list-container .search-container label {
    font-size: 13px !important;
    background-color: transparent;
}

.owl-theme .owl-nav {
    margin-top: 0 !important;
}

.form-switch .form-check-input {
    padding: unset;
    font-size: 16px;
}

.formmain .form-group {
    font-size: 13px;
    margin-bottom: 1rem !important;
}

.ng-star-inserted td .halfday_date::after {
    background: rgb(252, 104, 96);
    background: linear-gradient(270deg, rgba(252, 104, 96, 1) 50%, rgba(96, 176, 87, 1) 50%);
    width: 6px;
    height: 6px;
    position: absolute;
    border-radius: 50%;
    left: 51%;
    transform: translateX(-50%);
    top: unset;
    bottom: 6px;
}

.form-check-input[type=radio] {
    padding: 6px;
    border: solid 1px #a5a6a7;
}

.fc .fc-daygrid-day-frame {
    height: 150px;
}

/* btn loading */
button.btn-loading {
    pointer-events: none;
    opacity: 0.7;
}

.indicator-label {
    display: none !important;
}

.indicator-progress {
    display: block !important;
}

.mat-button-wrapper {
    pointer-events: none;
}

.indicator-progress {
    display: none;
    pointer-events: none;
}

.indicator-label {
    pointer-events: none;
}

button[disabled="disabled"] {
    opacity: 0.4;
    cursor: no-drop;
}


.formlanguage .wid80{
    height: 53px !important;
    padding-left: 15px !important;
    background-position: calc(100% - 10px);
}

.form-append .mat-icon{
    height: auto;
}
.formlanguage .form-group{
    align-items: center;
}
.mb-3.form-group.raddiv {
    margin-bottom: 10px !important;
}
.raddiv .form-check-label{
    top: 0;
    background: transparent;
    padding-left: 10px;
}


.dropsm ul.ng-star-inserted {
    position: fixed;
    max-height: 110px;
    overflow-y: auto;
    width: 200px;
    background: #f1f1f1;
    border-radius: 4px;
   
}




/* width */
.dropsm ul.ng-star-inserted::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

/* Track */
.dropsm ul.ng-star-inserted::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
}

/* Handle */
.dropsm ul.ng-star-inserted::-webkit-scrollbar-thumb {
    background: var(--color-blue);
    border-radius: 10px;
}

/* Handle on hover */
.dropsm ul.ng-star-inserted::-webkit-scrollbar-thumb:hover {
    background: var(--color-blue);
}


/* ------Scrollbar CSS End----- */

* {
    font-family: var(--font-main);
    transition: 0.5s;
    text-decoration: none;
}

.dropsm ul.ng-star-inserted li {
    padding: 8px 10px;
    border-bottom: solid 1px #fff;
    font-size: 12px;
    cursor: pointer;
}
.mb-3.form-group.amount.smbott, .mb-3.form-group.amount.smbott input{
    margin-bottom: 10px !important;
}

.userbox {
    padding: 8px 8px;
    border: solid 1px #d9d9d9;
    display: inline-block;
    margin-right: 5px;
    border-radius: 4px;
    margin-bottom: 4px;
    font-size: 12px;
}
.dropsm {
    margin-top: 0px;
}
.pointer{
    cursor: pointer;
}

.modalmain .container{
    max-width: 100% !important;
}

/* 404 Design start */
#main{
    display: table;
    width: 100%;
    height: 100vh;
    text-align: center;
}

.fof{
    display: table-cell;
    vertical-align: middle;
}

.fof h1{
    font-size: 50px;
    display: inline-block;
    padding-right: 12px;
    animation: type .5s alternate infinite;
}

@keyframes type{
    from{box-shadow: inset -3px 0px 0px #888;}
    to{box-shadow: inset -3px 0px 0px transparent;}
}
/* 404 Design End */

/* 16.05.25 */

.vaultbox {
    box-shadow: 0px 0px 20px #EBEBEB;
    border: 1px solid #FFFFFF80;
    border-radius: 10px;
    text-align: center;
    padding: 20px 15px;
    height: 100%;
    position: relative;
}

.vaultbox i {
    color: #007AFF;
    font-size: 50px;
    line-height: 50px;
}

.vaultbox h5 {
    margin: 5px 0 0 0 !important;
    color: #3E3E3E;
    font-size: 12px;
    line-height: 16px !important;
}
.vactionbtn button.acbtn {
    background: #ffffffbf;
    border: 1px solid #0050a800;
    border-radius: 50px;
    width: 35px;
    height: 35px;
    transition: 0.5s;
}

.vactionbtn button.acbtn i {
    font-size: 14px;
    line-height: normal;
}
.vactionbtn button.acbtn+button.acbtn {
    margin-left: 5px;
}

.vactionbtn {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000057;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    opacity: 0;
    z-index: 1;
}
.vaultbox:hover .vactionbtn{
    opacity: 1;
}
.vactionbtn button.acbtn:hover{
    background: #fff;
}
.innervaultbox i {
    color: #FFAC10;
}

.innervaultbox {
    background: #E6F2FF;
}

.expirevault{    
    background: #ffd1bc;
}
@-webkit-keyframes pulse {
	0% { background-color: #f06a2f; }
	100% { background-color: #f8ac60; }
}
@keyframes pulse {
	0% { background-color: #f06a2f; }
	100% { background-color: #f8ac60; }
}
.expiretxt {
    position: absolute;
    top: 10px;
    left: 0;
    background: #ff6b00;
    padding: 5px 10px;
    font-size: 10px;
    color: #fff;
    width: 100%;
    -webkit-animation: pulse 400ms infinite alternate;
    animation: pulse 400ms infinite alternate;
}



/* 16.05.25 */
/* dropdown main */
.mat-mdc-menu-content{
    background-color: #fff;
}
.btn-smm{
    background-color: #fff !important;
}

.btn-smm span{
    color: #000 !important;
}
.btn-smm:hover span{
    color: #333 !important;
}
.mat-tooltip {
    max-width: 500px !important; /* Change width as needed */
    white-space: normal !important; /* Allows text wrapping */
  }
  .my-custom-tooltip {
    max-width: unset !important;
  }
  
  .my-custom-tooltip .mdc-tooltip__surface {
    max-width: 260px;
  }
